<template>
  <div>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card-title class="font-weight-bold">
          {{ title(targetDay) }}
        </v-card-title>
        <v-card-title class="pt-0">
          <InputSearch height="44" v-model="search" />
        </v-card-title>

        <v-tabs v-model="tab" color="#6170E8" class="history-tab" show-arrows>
          <v-tabs-slider color="#6170E8"></v-tabs-slider>
          <v-tab v-for="item in tabList" :key="item.id" style="font-size: 20px">
            {{ item.text }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="1">
            <v-card flat>
              <v-data-table
                :height="heightDetail"
                :page.sync="allRecordPage"
                :items-per-page="itemsPerPage"
                :headers="entry_record_headers"
                :items="allRecordList"
                :search="search"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :custom-filter="customFilter"
                :no-results-text="$t('alert.child_not_found')"
                hide-default-footer
                fixed-header
                no-data-text=""
                class="elevation-1 all-record-page"
                item-style="font-size: 16px"
              >
                <template #[`item.enter`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    :color="item.enter ? '#161A1A' : '#DC313B'"
                  >
                    {{ getEnterIcon(item.enter) }}
                  </v-icon>
                </template>
                <template #[`item.leave`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    :color="item.leave ? '#161A1A' : '#DC313B'"
                  >
                    {{ getEnterIcon(item.leave) }}
                  </v-icon>
                </template>
                <template #[`item.absence`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    :color="item.absence ? '#161A1A' : '#DC313B'"
                  >
                    {{ getEnterIcon(item.absence) }}
                  </v-icon>
                </template>
              </v-data-table>
              <Pagination
                v-model="allRecordPage"
                :length="allRecordPageCount"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item key="2">
            <v-card flat>
              <v-data-table
                :height="heightDetail"
                :page.sync="missRecordPage"
                :items-per-page="itemsPerPage"
                :headers="record_headers"
                :items="missRecordList"
                :search="search"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :custom-filter="customFilter"
                hide-default-footer
                fixed-header
                no-data-text=""
                :no-results-text="$t('alert.child_not_found')"
                class="elevation-1 miss-record"
                item-style="font-size: 16px"
                item-key="memberId"
              >
                <template #[`item.status`]="{ index, item }">
                  <div
                    :class="{
                      'disable-events': missRecordLoadingList[index].loading,
                    }"
                  >
                    <ENJIBtn
                      color="#6170E8"
                      height="44"
                      :key="'enter_room' + item.memberId"
                      :class="{ 'disable-events': item.enter, 'mr-2': true }"
                      class="checkin-btn"
                      @click="manualCheck(index, 'in', item.memberId)"
                    >
                      {{ $t('manager_main.enter_room') }}</ENJIBtn
                    >
                    <ENJIBtn
                      color="#6170E8"
                      height="44"
                      :key="'leave_room' + item.memberId"
                      :class="{
                        'disable-events': item.leave || !item.enter,
                        'mr-2': true,
                      }"
                      class="checkin-btn"
                      @click="manualCheck(index, 'out', item.memberId)"
                    >
                      {{ $t('manager_main.leave_room') }}</ENJIBtn
                    >
                    <ENJIBtn
                      color="#6170E8"
                      height="44"
                      :key="'absence' + item.memberId"
                      :class="{
                        'disable-events': item.enter || item.leave,
                        'mr-2': true,
                      }"
                      class="last-checkin-btn"
                      @click="manualCheck(index, 'absence', item.memberId)"
                    >
                      {{ $t('manager_main.absence') }}</ENJIBtn
                    >
                  </div>
                </template>
                <template #[`item.enter`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    :color="item.enter ? '#161A1A' : '#DC313B'"
                  >
                    {{ getEnterIcon(item.enter) }}
                  </v-icon>
                </template>
                <template #[`item.leave`]="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    :color="item.leave ? '#161A1A' : '#DC313B'"
                  >
                    {{ getEnterIcon(item.leave) }}
                  </v-icon>
                </template>
                <!-- <template v-slot:no-results>
                    <span>{{ $t('alert.not_found') }}</span>
                  </template> -->
              </v-data-table>
              <Pagination
                v-model="missRecordPage"
                :length="missRecordPageCount"
              />
            </v-card>
          </v-tab-item>

          <v-tab-item key="3">
            <v-card flat>
              <v-data-table
                :height="heightDetail"
                :page.sync="attendancePage"
                :items-per-page="itemsPerPage"
                :headers="history_headers"
                :items="historyList"
                :search="search"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :custom-filter="customFilter"
                hide-default-footer
                fixed-header
                no-data-text=""
                :no-results-text="$t('alert.child_not_found')"
                class="elevation-1"
                item-style="font-size: 16px"
              >
                <template #[`item.status`]="{ item }">
                  {{ formatStatus(item.status) }}
                </template>
                <template #[`item.name`]="{ item }">
                  {{
                    item.name === '不明な園児'
                      ? $t('history.noname')
                      : item.name
                  }}
                </template>
                <template #[`item.timestamp`]="{ item }">
                  {{ format(item.timestamp) }}
                </template>
                <template #[`item.type`]="{ item }">
                  <v-icon size="20" class="mr-2">
                    {{ authIcon(item.type) }} </v-icon
                  >{{ authIconName(item.type) }}
                </template>
                <!-- <template v-slot:no-results>
                    <span>{{ $t('alert.not_found') }}</span>
                  </template> -->
              </v-data-table>
              <Pagination
                v-model="attendancePage"
                :length="attendancePageCount"
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
/*== custom Table ==*/
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th
  > span {
  color: #5d6060;
  font: normal normal normal 16px/18px Noto Sans JP;
  font-size: 16px;
  word-break: break-word !important;
}
.elevation-1.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 16px;
  font: normal normal normal 16px/18px Noto Sans JP;
  color: #161a1a;
  min-height: 57px;
  height: 57px;
}

.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(1),
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:nth-child(1) {
  padding-left: 15px;
  padding-right: 0;
}
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(2),
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:nth-child(2) {
  padding-left: 6px;
  padding-right: 16px;
}
@media only screen and (max-width: 1600px) {
  ::v-deep(.miss-record.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr
      > td:nth-child(2)),
  ::v-deep(.miss-record.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr
      > td:nth-child(3)),
  ::v-deep(.miss-record.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr
      > td:nth-child(4)),
  ::v-deep(.all-record-page.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr
      > td:nth-child(2)),
  ::v-deep(.all-record-page.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr
      > td:nth-child(3)),
  ::v-deep(.all-record-page.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr
      > td:nth-child(4)) {
    padding: 0 !important;
  }
  ::v-deep(.miss-record.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr
      > td:nth-child(4) button) {
    min-width: 50px !important;
    font-size: 12px !important;
    padding: 0px 5px !important;
    height: 30px !important;
  }
}
@media only screen and (min-width: 1265px) and (max-width: 1600px) {
  ::v-deep(.miss-record.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr
      > td:nth-child(4) button) {
    min-width: 40px !important;
    font-size: 10px !important;
    padding: 0px 4px !important;
    height: 30px !important;
  }
}
@media only screen and (max-width: 1265px) {
  .checkin-btn {
    margin-right: 3px !important;
  }
  .last-checkin-btn {
    margin-right: 0px !important;
  }
}
.history-tab {
  border-bottom: 1px solid #d0d0d0;
}
.disable-events {
  pointer-events: none;
  opacity: 0.3;
}
</style>

<script>
import dayjs from 'dayjs'
import Pagination from '@/components/Pagination.vue'
import InputSearch from '@/components/inputs/InputSearch.vue'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'

export default {
  components: {
    InputSearch,
    Pagination,
    ENJIBtn,
  },
  props: {
    memberList: {
      type: Array,
      required: true,
    },
    heightDetail: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      targetDay: dayjs().format('YYYY-MM-DD'),
      search: '',
      sortBy: 'timestamp',
      sortDesc: true,
      historyList: [],
      history_headers: [
        { text: this.$t('history.person_enter_leave_room'), value: 'name' },
        { text: this.$t('history.status'), value: 'status' },
        { text: this.$t('history.auth_method'), value: 'type' },
        { text: this.$t('history.entry_exit_time'), value: 'timestamp' },
      ],
      record_headers: [
        {
          text: this.$t('history.person_enter_leave_room'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('manager_main.enter_room'),
          value: 'enter',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('manager_main.leave_room'),
          value: 'leave',
          align: 'center',
          sortable: false,
        },
        { value: 'status', sortable: false },
      ],
      entry_record_headers: [
        {
          text: this.$t('history.person_enter_leave_room'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('manager_main.enter_room'),
          value: 'enter',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('manager_main.leave_room'),
          value: 'leave',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('manager_main.absence'),
          value: 'absence',
          align: 'center',
          sortable: false,
        },
      ],
      tab: null,
      attendancePage: 1,
      missRecordPage: 1,
      allRecordPage: 1,
      itemsPerPage: 100,
      tabList: [
        { id: 1, text: this.$t('manager_main.enter_and_leave') },
        { id: 2, text: this.$t('manager_main.miss_record') },
        { id: 3, text: this.$t('manager_main.attendance_history') },
      ],
      missRecordLoadingList: [],
    }
  },
  computed: {
    attendancePageCount() {
      return Math.ceil(this.historyList.length / this.itemsPerPage)
    },
    missRecordPageCount() {
      return Math.ceil(this.missRecordList.length / this.itemsPerPage)
    },
    allRecordPageCount() {
      return Math.ceil(this.allRecordList.length / this.itemsPerPage)
    },
    missRecordList() {
      var attendance = {}
      var absenceMemberList = {}
      this.historyList.map((v) => {
        // if child is unknow or absent, not add to missRecordList
        if (absenceMemberList[v.memberId]) {
          return
        }
        if (v.status == this.$constants.checkInStatus.absence) {
          absenceMemberList[v.memberId] = true
          return
        }
        attendance[v.memberId] = attendance[v.memberId] || {}
        attendance[v.memberId].name = v.name
        attendance[v.memberId].tags = v.tags
        attendance[v.memberId].memberId = v.memberId
        attendance[v.memberId].loading = false

        switch (v.status) {
          case this.$constants.checkInStatus.checkIn: // enter
            attendance[v.memberId].enter = true
            break
          case this.$constants.checkInStatus.checkOut: // leave
            attendance[v.memberId].leave = true
            break
          default:
            break
        }
      })
      // Add detail of no checkin, checkout childs
      this.memberList.map((v) => {
        // if Child already in missRecordList or child not in missRecordList but in absenceList, dont add this chill to missRecordList
        if (attendance[v.id] || absenceMemberList[v.id]) {
          return
        }

        attendance[v.id] = {
          memberId: v.id,
          name: `${v.firstname} ${v.lastname}`,
          tags: v.tags,
          enter: false,
          leave: false,
          loading: false,
        }
      })

      var absenceList = Object.values(attendance).filter(
        (v) => !(v.enter && v.leave)
      )
      this.missRecordLoadingList = absenceList
      return absenceList
    },

    allRecordList() {
      var attendance = {}
      this.historyList.map((v) => {
        attendance[v.memberId] = attendance[v.memberId] || {}
        attendance[v.memberId].name = v.name
        attendance[v.memberId].tags = v.tags
        attendance[v.memberId].memberId = v.memberId
        switch (v.status) {
          case this.$constants.checkInStatus.checkIn: // enter
            attendance[v.memberId].enter = true
            break
          case this.$constants.checkInStatus.checkOut: // leave
            attendance[v.memberId].leave = true
            break
          case this.$constants.checkInStatus.absence: // absence
            attendance[v.memberId].absence = true
            break
          default:
            break
        }
      })

      this.memberList.forEach((v) => {
        if (attendance[v.id]) {
          return
        }

        attendance[v.id] = {
          memberId: v.id,
          name: `${v.firstname} ${v.lastname}`,
          tags: v.tags,
          enter: false,
          leave: false,
          absence: false,
        }
      })

      return Object.values(attendance)
    },
  },
  methods: {
    title(date) {
      return dayjs(date).format(
        this.$i18n.locale === 'ja'
          ? this.$constants.dateFormatDayJP
          : this.$constants.dateFormat
      )
    },
    setData(selectedDay, historyList) {
      this.attendancePage = 1
      this.missRecordPage = 1
      this.targetDay = selectedDay
      this.historyList = historyList
      this.showLoading({ show: false })
    },
    format(date) {
      return dayjs(date).format(
        this.$i18n.locale === 'ja' ? 'HH時mm分ss秒' : 'HH:mm:ss'
      )
    },
    formatStatus(value) {
      switch (value) {
        case this.$constants.checkInStatus.checkIn:
          return this.$t('manager_main.enter_room')
        case this.$constants.checkInStatus.checkOut:
          return this.$t('manager_main.leave_room')
        case this.$constants.checkInStatus.absence:
          return this.$t('manager_main.absence')
      }
    },
    authIcon(value) {
      switch (value) {
        case this.$constants.checkInType.face:
          return 'mdi-face-recognition'
        case this.$constants.checkInType.qr:
          return 'mdi-qrcode'
      }
    },
    getEnterIcon(value) {
      switch (value) {
        case true:
          return 'mdi-checkbox-blank-circle-outline'
        case false:
        case undefined:
          return 'mdi-close'
      }
    },
    authIconName(value) {
      switch (value) {
        case this.$constants.checkInType.face:
          return this.$t('member_check.face_reg')
        case this.$constants.checkInType.qr:
          return this.$t('kindergarten_list.qrcode')
        case this.$constants.checkInType.manual:
          return this.$t('member_check.manual')
      }
    },
    getChipColor(value) {
      switch (value) {
        case this.$constants.checkInStatus.checkIn:
          return 'success'
        case this.$constants.checkInStatus.checkOut:
          return 'error'
      }
    },
    manualCheck(index, type, memberId) {
      this.showLoading({ show: true, text: this.$t('alert.processing') })
      this.missRecordLoadingList[index].loading = true
      var endOfDay = dayjs(this.targetDay)
        .set('hour', 21)
        .set('minute', 59)
        .valueOf()
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          var memberCount = this.missRecordList.length
          if (this.historyList.length !== 0) {
            memberCount = this.historyList[0].member_count
          }
          this.$axiosM
            .post(
              `/check/${type}/manual`,
              {
                id: memberId,
                timestamp: endOfDay,
                company_id: auth.companyId,
                member_count: memberCount,
              },
              {
                headers: {
                  Authorization: auth.idToken,
                },
              }
            )
            .then((response) => {
              if (response.status == 200) {
                this.$emit('onResetDetail', this.targetDay)
              }
            })
            .catch((error) => {
              this.$emit('onResetDetail', this.targetDay)
              console.log(error)
              this.showSnack({
                text: this.$t('alert.send_failed'),
                color: 'error',
                timeout: 3000,
              })

              this.missRecordLoadingList[index].loading = false
            })
        })
        .catch((__error) => {
          this.loading = false
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },

    customFilter(value, search, item) {
      const searchLowerCase = this.search.toLowerCase()
      return (
        item.name.toLowerCase().includes(searchLowerCase) ||
        (item.tags?.length > 0 &&
          item.tags.some((tag) =>
            tag.name.trim().toLowerCase().includes(searchLowerCase)
          ))
      )
    },
  },
}
</script>

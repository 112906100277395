<template>
  <div style="height: 100%">
    <v-sheet height="70" class="mt-4">
      <v-toolbar flat dense class="calendar-toolbar">
        <v-toolbar-title class="date">
          {{ title }}
        </v-toolbar-title>
        <div class="ml-auto">
          <v-btn
            width-="48"
            height="48"
            class="mr-4 toolbar-action"
            outlined
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small style="font-size: 32px"> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn
            width-="48"
            height="48"
            outlined
            class="mr-4 toolbar-action today"
            color="grey darken-2"
            @click="setToday"
          >
            {{ $t('kindergarten_list.today') }}
          </v-btn>
          <v-btn
            width-="48"
            height="48"
            class="mr-1 toolbar-action"
            outlined
            color="grey darken-2"
            @click="next"
          >
            <v-icon small style="font-size: 32px"> mdi-chevron-right </v-icon>
          </v-btn>
        </div>
      </v-toolbar>
    </v-sheet>
    <v-sheet height="calc(100vh - 200px)" class="calendar-panel">
      <v-calendar
        ref="calendar"
        :locale="getLocale"
        v-model="focus"
        color="primary"
        :events="events"
        :event-color="getEventColor"
        type="month"
        :event-more="false"
        :event-text-color="getEventTextColor"
        :event-margin-bottom="4"
        @click:day="handleDayClick"
        @change="updateRange"
      >
        <template #event="{ event }">
          <div class="event-name text-center" v-html="event.name">
          </div>
        </template>
        <template v-slot:day="{ date }">
          <div
            v-if="date === focus"
            style="
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border: 2px solid #6170e8;
              z-index: 2;
            "
            :style="{
              'background-color': hasAbsenceDays.includes(date)
                ? 'transparent'
                : '#6170E81A',
            }"
          >
            &nbsp;
          </div>
          <div v-for="datelack in hasAbsenceDays" :key="datelack">
            <span
              v-if="date === datelack"
              style="position: absolute; top: 0; left: 0; right: 0; bottom: 0"
              :style="{
                'background-color': '#DC313B1A',
                border: '1px solid #EEEEEE',
              }"
            >
              &nbsp;
            </span>
          </div>
        </template>
      </v-calendar>
    </v-sheet>
  </div>
</template>
<style scoped>
/*== Calendar header toolbar ==*/
.calendar-toolbar .v-btn:not(.v-btn--round).v-size--default {
  min-width: 48px;
  border: 2px solid #333333;
  border-radius: 6px;
  font-size: 20px;
  color: #333333 !important;
  padding: 0;
}
.calendar-toolbar .today {
  padding: 0 5px !important;
}
.calendar-toolbar .date {
  font-size: 32px;
  font-weight: 700;
}
.calendar-toolbar ::v-deep(.v-toolbar__content) {
  padding: 4px;
}

.calendar-panel {
  min-height: 680px;
  height: calc(-200px + 100vh);
  padding-bottom: 20px;
}

/*== Calendar panel ==*/
.calendar-panel.v-sheet ::v-deep(.v-calendar-weekly__head-weekday),
.calendar-panel.v-sheet ::v-deep(.v-calendar-weekly__head-weekday.v-outside),
.calendar-panel.v-sheet
  ::v-deep(.v-calendar-weekly__head-weekday.primary--text) {
  background-color: #6170e81a;
  color: #333333 !important;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.calendar-panel.v-sheet ::v-deep(.v-calendar-weekly__day.v-outside) {
  background-color: transparent;
  pointer-events: none;
  opacity: 0.3;
}

.calendar-panel.v-sheet ::v-deep(.v-calendar-weekly__day-label) {
  margin: -5px 0 0 0;
}

.calendar-panel.v-sheet ::v-deep(.v-calendar-weekly__day-label > button) {
  font-size: 16px;
  background-color: transparent !important;
  color: #333333;
}
.calendar-panel.v-sheet ::v-deep(.v-calendar-weekly__day > .v-event) {
  position: absolute !important;
  bottom: 16px !important;
  width: 100% !important;
  font-size: 13px !important;
  height: 64% !important;
  margin-bottom: -5px !important;
}
.calendar-panel.v-sheet ::v-deep(.v-calendar-weekly__day-label > button > span) {
  width: 100%;
  height: 100%;
}
.calendar-panel.v-sheet ::v-deep(.v-btn--fab.v-size--small) {
  height: 30px !important;
  width: 30px !important;
}
.calendar-panel.v-sheet ::v-deep(.event-icon) {
  display: flex;
  justify-content: space-evenly;
}
.calendar-panel.v-sheet ::v-deep(.event-icon > img) {
  width: 20px;
  height: 20px;
}
.calendar-panel.v-sheet ::v-deep(.v-calendar-weekly__week) {
  min-height: 85px;
}

::v-deep(.v-calendar-weekly__day.v-future) {
  pointer-events: none;
  opacity: 0.3;
}
@media only screen and (max-width: 1265px) {
  .calendar-panel.v-sheet ::v-deep(.v-calendar-weekly__day > .v-event) {
    bottom: 0.25rem !important;
  }
}
@media only screen and (max-width: 1400px) {
  .calendar-panel.v-sheet ::v-deep(.v-calendar-weekly__day > .v-event) {
    font-size: 12px !important;
  }
  .calendar-panel.v-sheet ::v-deep(.event-icon > img) {
    width: 18px !important;
    height: 18px !important;
  }
}
</style>

<script>
import dayjs from 'dayjs'
import checkinIcon from '@/assets/home-import-outline.svg'
import checkoutIcon from '@/assets/home-export-outline.svg'
import absenceIcon from '@/assets/home-remove-outline.svg'

export default {
  props: {
    historyList: {
      type: Array,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    focus: dayjs().format('YYYY-MM-DD'),
    date: '2023-11-24',
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    hasAbsenceDays: [],
    detailMemberList: [],
    checkinIcon: checkinIcon,
    checkoutIcon: checkoutIcon,
    absenceIcon: absenceIcon,
  }),
  computed: {
    title() {
      if (this.$i18n.locale == 'ja') {
        return dayjs(this.focus).format('YYYY年 M月')
      } else {
        return dayjs(this.focus).format('YYYY-MM')
      }
    },
    getLocale() {
      return this.$i18n.locale
    },
  },
  methods: {
    handleDayClick({ date }) {
      this.focus = date
      var firstDayOfMonth = dayjs(date).startOf('month').format('YYYY-MM-DD')
      this.updateRange({ start: { date: firstDayOfMonth }, isShowLoading: true})
    },
    setToday() {
      this.focus = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
      this.handleDayClick({ date: this.focus })
    },
    next() {
      this.$refs.calendar.next()
    },
    prev() {
      this.$refs.calendar.prev()
    },
    getEventColor(event) {
      return event.color
    },
    getEventTextColor(event) {
      return event.textColor
    },
    updateRange({ start, isShowLoading }) {
      if (isShowLoading !== false) {
        this.showLoading({ show: true, text: this.$t('alert.processing') })
      }
      this.$cognito.isAuthenticated().then(async (auth) => {
        this.$axiosM
          .post(
            '/history/list',
            {
              start: start.date,
              company_id: auth.companyId,
            },
            {
              headers: {
                Authorization: auth.idToken,
              },
            }
          )
          .then((response) => {
            let newEvents = []
            this.hasAbsenceDays = []
            let attendance = {}
            const memberList = response.data.memberList
            const historyList = response.data.historyList
            historyList.map((history) => {
              const timestamp = dayjs(history.timestamp).format(
                this.$constants.dateFormat
              )
              attendance[timestamp] = attendance[timestamp] || {}

              const member = memberList.find(
                (member) => history.memberId === member.id
              )
              if (member) {
                history.name = `${member.firstname} ${member.lastname}`
                history.tags = member.tags
              } else {
                history.name = this.$t('history.deleted_child')
              }
              attendance[timestamp]['memberCount'] =
                attendance[timestamp]['memberCount'] || history.member_count
              attendance[timestamp][history.memberId] = attendance[timestamp][
                history.memberId
              ] || { in: false, out: false }
              switch (history.status) {
                case this.$constants.checkInStatus.checkIn:
                  attendance[timestamp][history.memberId]['in'] = true
                  break
                case this.$constants.checkInStatus.checkOut:
                  attendance[timestamp][history.memberId]['out'] = true
                  break
                case this.$constants.checkInStatus.absence:
                  // attendance[timestamp][history.memberId]['in'] = true
                  // attendance[timestamp][history.memberId]['out'] = true
                  attendance[timestamp][history.memberId]['absence'] = true
                  break
              }
            })
            const startOfNextDay = (timeParam) =>
              dayjs(timeParam).add(1, 'day').startOf('day').valueOf()
            this.detailMemberList = memberList.filter(
              (member) => !(member.created_at > startOfNextDay(this.focus))
            )
            for (const timeKey in attendance) {
              const memberCountOfThisDay = attendance[timeKey]['memberCount']
              const memberCheckList = attendance[timeKey]
              // get count of full checkin member in this day
              // Todo: from #158 can delete it
              const fullAttendanceCount = Object.values(memberCheckList).reduce(
                (count, value) =>
                  count + (value.in === true && value.out === true ? 1 : 0),
                0
              )
              // get count of checkin only member in this day
              const checkinAttendanceCount = Object.values(memberCheckList).reduce(
                (count, value) =>
                  count + (value.in === true ? 1 : 0),
                0
              )
              // get count of checkout only member in this day
              const checkoutAttendanceCount = Object.values(memberCheckList).reduce(
                (count, value) =>
                  count + (value.out === true ? 1 : 0),
                0
              )
              // get count of absence only member in this day
              const absenceAttendanceCount = Object.values(memberCheckList).reduce(
                (count, value) =>
                  count + (value.absence === true ? 1 : 0),
                0
              )
              // set event name of calendar
              const checkinHtml = `<div class="event-icon"><img src="${checkinIcon}" />${checkinAttendanceCount}/${memberCountOfThisDay}</div>`
              const checkoutHtml = `<div class="event-icon"><img src="${checkoutIcon}" />${checkoutAttendanceCount}/${memberCountOfThisDay}</div>`
              const absenceHtml = `<div class="event-icon"><img src="${absenceIcon}" />${absenceAttendanceCount}/${memberCountOfThisDay}</div>`
              
              const eventName = `${checkinHtml}${checkoutHtml}${absenceHtml}`;

              const event = {
                name: eventName,
                start: timeKey,
                end: timeKey,
                textColor:
                  fullAttendanceCount < memberCountOfThisDay
                    ? '#DC313B'
                    : '#333333',
                timed: true,
              }
              newEvents.push(event)

              if (fullAttendanceCount < memberCountOfThisDay) {
                this.hasAbsenceDays.push(timeKey)
              }
            }

            this.$emit('update', historyList, newEvents)
            this.$emit(
              'detail',
              this.focus,
              historyList.filter((history) => {
                const start = dayjs(history.timestamp).format(
                  this.$constants.dateFormat
                )
                if (start === this.focus) return true
              }),
              this.detailMemberList
            )
          })
          .catch((error) => {
            console.log(error)
            this.showLoading({ show: false })
            this.showSnack({
              text: this.$t('alert.error_get_data_failed'),
              color: 'error',
              timeout: 6000,
            })
          })
      })
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
}
</script>

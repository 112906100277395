<template>
  <div class="custom-text">
    <div style="height: 100%">
      <div class="pa-0" style="height: 100%">
        <v-row justify="center" class="ma-0" style="height: 100%">
          <v-col cols="6" md="7" style="padding: 0 12px 0 0; height: 100%">
            <v-card class="main mx-auto" style="height: 100%">
              <v-card-title class="border-bottom mb-2">
                <v-row style="height: 100%">
                  <v-col style="height: 100%">
                    <Calendar
                      ref="calendar"
                      :history-list="historyList"
                      :events="events"
                      @update="update"
                      @detail="detail"
                      style="height: 100%"
                    />
                  </v-col>
                </v-row>
              </v-card-title>
            </v-card>
          </v-col>
          <v-col cols="6" md="5" style="padding: 0px; height: 100%">
            <v-card class="main mx-auto overflow-hidden" ref="detailBox" style="height: 100%">
              <div class="border-bottom mb-0 px-0 pb-0">
                <v-row>
                  <v-col>
                    <Detail
                      ref="form"
                      :memberList="memberList"
                      :heightDetail="detailBoxHieght"
                      @onResetDetail="resetDetail"
                    />
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Calendar from '@/components/history/Calendar.vue'
import Detail from '@/components/history/Detail.vue'

export default {
  components: {
    Calendar,
    Detail,
  },
  computed: {
    APP_TITLE: {
      get: function () {
        return process.env.VUE_APP_TITLE
      },
    },
  },
  data() {
    return {
      memberList: [],
      historyList: [],
      events: [],
      detailBoxHieght: 0
    }
  },
  created() {
    this.isAuthenticated()
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    await this.handleResize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },


  methods: {
    isAuthenticated() {
      this.$cognito
        .isAuthenticated()
        .then(() => {})
        .catch((_session) => {
          this.$router.replace('/manager/signin')
        })
    },
    signOut() {
      this.$cognito.logout()
      this.$router.replace('/manager/signin')
    },
    update(historyList, events) {
      this.historyList = historyList
      this.events = events
    },
    detail(selectedDay, historyList, detailMemberList) {
      this.memberList = detailMemberList
      this.$refs.form.setData(selectedDay, historyList)
    },
    resetDetail(selectedDay) {
      var firstDayOfMonth = dayjs(selectedDay)
        .startOf('month')
        .format('YYYY-MM-DD')
      this.$refs.calendar.updateRange({ start: { date: firstDayOfMonth }, isShowLoading: true })
    },
    async handleResize() {
      await this.$nextTick(() => {
        this.detailBoxHieght = this.$refs.detailBox.$el.clientHeight - 250;
      });
    }
  },
}
</script>
